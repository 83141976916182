.SideTabSectionCompContainer {
  position: relative;
}

.SideTabSectionCompIndicatorContainer {
  position: absolute;
  left: 0;
  z-index: -1;
  transition: top 0.15s ease-out;
  background: #f57c00;
}

.SideTabSectionCompArrow {
  width: 0;
  height: 0;
  border-left: 10px solid #f57c00;
  position: absolute;
  top: 0;
  right: -10px;
}
