.searchBarContainer {
  flex: 1;
  display: flex;
  min-height: 50px;
  margin-bottom: 24px;
  align-items: center;
  .searchBarScroll {
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }
}

.chipContainer {
  margin: 0 8px;
}
