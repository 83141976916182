body {
  margin: 0;
  font-family: 'titilliumtext25l400_wt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #0e6ccc; /* Safari */
  color: #fff;
}

::-moz-selection {
  background: #0e6ccc; /* Firefox */
  color: #fff;
}

code {
  font-family: 'titilliumtext25l400_wt', sans-serif;
}
