.mediumAvatar {
  height: 24px !important;
  width: 24px !important;
  font-size: 12px !important;
}

.smallAvatar {
  height: 18px !important;
  width: 18px !important;
  font-size: 10px !important;
}
