.noAccessPage {
  display: flex;
  justify-content: center;
  align-items: center;
  .noAccessPageCenter {
    display: flex;
    flex-direction: column;
    max-width: 440px;
    .noAccessPageText {
      width: 440px;
      text-align: center;
      .noAccessPageTitle {
        font-size: 36px;
        font-weight: 600px;
        padding: 10px 0;
      }
      .noAccessPageP {
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
}
