.MobileAppbar {
  width: 100%;
  overflow: hidden;
  .MobileAppbarToolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    white-space: nowrap;
    .MobileAppbarLeft {
      display: flex;
      align-items: center;
      float: left;
      text-overflow: ellipsis;
    }
    .MobileAppbarRight {
      float: right;
      display: flex;
      align-items: center;
    }
  }
}
