.SideNavSectionContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    color: #bdbdbd;
  }
  .SideNavSectionIcon {
    color: white;
  }
  .SideNavSectionText {
    font-size: 0.6rem;
  }
}
