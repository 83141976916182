.branchMenu {
  width: 234px;
  .branchList {
    min-width: 160px;
    a {
      color: rgba(
        0,
        0,
        0,
        0.87
      ); // needed to avoid the color change that was created globally for this
    }
  }
}
