.equipToolbarOuterBox {
  width: 100%;
  position: fixed;
  .equipToolbarContainer {
    right: 0;
    display: flex;
    padding: 0 20px;
    position: fixed;
    font-size: 15px;
    background: #f5f5f5;
    min-height: 48px;
    align-items: center;
  }
}
