.monitorContainer {
  width: 100%;
  .monitorTimeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .monitorToolbarRight {
      display: flex;
      align-items: center;
    }
  }
}
