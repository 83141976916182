.userProfilePage {
  .userProfilePageBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 96px;
    .userProfileAttributes {
      padding: 10px 32px;
    }
  }
}
