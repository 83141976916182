.viewport {
  display: flex;
}

.fab {
  position: fixed !important;
  right: 8px;
  z-index: 10;
  bottom: 64px;
}

.sideNavInner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.childrenContainer {
  height: 100%;
  width: calc(100vw - 80px);
}
