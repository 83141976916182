.linkContainer {
  display: flex;
  height: 100%;
  .appBarLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 14px;
    margin-top: 3px;
    text-transform: uppercase;
    text-align: center;
    height: 100%;
    min-width: 0;
    max-width: 132px;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    line-height: 2;
    font-weight: 400;
    color: #666;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
