.dashBoardBody {
  display: flex;
  height: calc(100vh - 64px);
}

.dashBoardReport {
  width: 100%;
  .innerDashBoardBody {
    width: 10px;
    height: 100%;
    margin-left: 1px;
  }
  .recentActivityDash {
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
