.registrationFooterContainter {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  .registrationFooter {
    padding: 5px 30px 20px 30px;
    width: 98vw;
    .registrationFooterInner {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .registrationCopyRight {
        color: #9e9e9e;
        font-size: 11px;
        margin-right: 20px;
      }
      .registrationFooterRight {
        display: flex;
        .registrationTermsofService {
          color: #9e9e9e;
          font-size: 11px;
          margin-right: 20px;
        }
        .registrationPrivacyPolicy {
          color: #9e9e9e;
          font-size: 11px;
        }
      }
    }
  }
}
