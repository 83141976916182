.requiredStarSpan {
  color: red;
}

.cardItemBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
}
