.fieldsContainer {
  display: flex;
  .fieldBox {
    display: flex;
    flex-direction: column;
    padding: 2px;
    min-height: 36px;
    .fieldTitle {
      font-size: 8px;
      text-transform: uppercase;
      color: rgb(153, 153, 153);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 5px;
    }
    .fieldInfo {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
