.buttonIconDisable {
  opacity: 0.26;
}
.row {
  width: 30px;
  height: 30px;
  padding: 0;
}
.appBar {
  color: #fff;
}
.navbar {
  color: #fafafa;
}
.navbar:hover {
  color: #bdbdbd;
}
