.RecentActivityBox {
  overflow-y: scroll;
  position: absolute;
  height: 98%;
  width: 93%;
  font-size: 13px; // set this here after switching to material-ui alpha it really messed with styles across the board, this is one it affected

  .ActivityRecentTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ActivityCol {
    display: flex;
    flex-direction: column;
    .ActivityRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 250px;
      height: 30px;
    }
    .ActivityLowerRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 14px;
      max-width: 250px;
      height: 26px;
    }
  }
}
