.contactCard {
  flex: 1;
  margin-bottom: 7px;
  margin-right: 7px;
  height: 240px;
  min-width: 250px;
  .contactCardContainer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .contactCardImageContainer {
      flex: 1;
      display: flex;
      align-items: flex-start;
      position: relative;
      .contactCardImageLabel {
        cursor: pointer;
      }
    }
    .contactInfoTextContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5;
    }
  }
}

.contactText {
  margin: 4px;
}
