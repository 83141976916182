.reportBlock {
  display: grid;
}

.reportToolBar {
  position: sticky;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  .searchBox {
    margin: 0 15px;
  }
}
