.forgotOuterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.forgotContainer {
  display: flex;
  padding: 20px;
  max-width: 400px;
  min-width: 325px;
  flex-direction: column;
}

.forgotInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 30px;
}

.forgotLogoh1 {
  margin-bottom: 0;
}

.forgotLogo {
  width: 160px;
  height: 102px;
}

.forgotTitle {
  margin-top: 20px;
}

.returnToLogin {
  margin-right: 16px;
}

.submitEmailContainer {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.forgotText {
  width: 300px;
}

.forgotInstructions {
  color: #9e9e9e;
  margin: 30px 0 !important;
}

.forgotEmailField {
  width: 100%;
}
