.resetPassOuterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.resetPassContainer {
  display: flex;
  padding: 20px;
  max-width: 400px;
  min-width: 325px;
  flex-direction: column;
}

.resetPassInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 30px;
}

.resetPassLogoh1 {
  margin-bottom: 0;
}

.resetPassLogo {
  width: 160px;
  height: 102px;
}

.resetPassTitle {
  margin-top: 30px;
}

.returnToLogin {
  margin-right: 16px;
}

.submitEmailContainer {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resetPassText {
  width: 300px;
}

.resetPassTextTop {
  width: 100%;
  margin-bottom: 10px;
}

.resetPassNoSideMargin {
  width: 100% !important;
  margin: 8px 0 !important;
}

.resetPassInstructions {
  color: #9e9e9e;
  margin: 30px 0 !important;
}
