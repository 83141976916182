// This animation controls when the bar enters
.expand {
  animation: expandWidth ease-in 1s;
}

@keyframes expandWidth {
  0% {
    width: 32px;
  }
  100% {
    width: 100%;
  }
}

// This animation controls when the bar leaves
.reduce {
  animation: reduceWidth ease-out 1s;
}

@keyframes reduceWidth {
  0% {
    // This is a percentage which causes it to have a delay, if you want to remove a delay turn this to the maximum width in pixels
    width: 100%;
  }
  100% {
    width: 32px;
  }
}

// This animation controls when the bar enters
.containerExpand {
  animation: expandHeight ease-in 1s;
}

@keyframes expandHeight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}