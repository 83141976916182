#htmlPrintablePriceSheet {
  /* background-color: #999; */
  display: block;
}

.pageTitle {
  text-align: center;
  font-weight: 900;
  border-bottom: 0.02in solid #666;
}

.title {
  text-align: center;
  font-weight: 900;
}

.no-print {
  display: none;
}

.category {
  font-weight: 800;
  padding: 0.2in 0.1in 0in 0in;
  /* border-block: 2px solid #555; */
  /* border-width: 2px;
  border-style: solid;
  border-color: #555; */
  border-bottom: 0.02in solid #555;
}

.model {
  padding: 0.1in 0.1in 0.1in;
  /* border-block: 2px solid #555; */
  border-bottom: 0.02in solid #555;
}

.equipment {
  /* border-block: 2px solid#999; */
  border-bottom: 0.02in solid #999;
  padding-left: 0.2in;
}

.price {
  white-space: nowrap;
  /* border-block: 2px solid#999; */
  border-bottom: 0.02in solid #999;
  padding: 5px 20px;
}
