.tasksPageContainer {
  margin: 24px;
  .tasksInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .tasksOverviewContainer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .taskOverviewPapers {
        width: 240px;
        height: 167px;
        margin: 6px 20px;
        .taskOverviewTitles {
          display: flex;
          padding: 16px;
          align-items: center;
          justify-content: center;
        }
        .taskOverviewPreview {
          display: flex;
          flex-direction: column;
          padding: 16px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .tasksAccordianContainer {
      max-width: 722px;
      width: 100%;
      padding-bottom: 50px;
    }
    .groupedTasksContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      align-items: center;
      .groupTaskAccordians {
        max-width: 722px;
        width: 100%;
      }
    }
  }
}

.heading {
  white-space: pre;
  overflow: hidden;
  width: 40%;
  margin-right: 20px;
}

.headingMobile {
  overflow: hidden;
  width: 40%;
  margin-right: 20px;
}

.secondaryHeading {
  flex: 1;
}

.list {
  width: 100%;
}

.listText {
  width: 230px;
  margin-right: 40px;
  flex: 0 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}
.dueContainer {
  width: 200px;
}
.progressContainer {
  width: 140px;
  flex-shrink: 0px;
}
.progressContainerMobile {
  width: 80px;
  flex-shrink: 0;
}
.expansionPanelDetailsMobile {
  padding-right: 0;
  padding-left: 0;
}
.expansionPanelSummaryMobile {
  padding-right: 5px;
  padding-left: 5px;
}
.progressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 50%;
  color: white;
  font-size: 12px;
}
.progressCircleOuter {
  padding: 1px;
}
.progressCircleContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
}
