.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}
.loginContainer {
  display: flex;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
}
.loginInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 30px;
}
.bottomInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 30px;
}
.logoh1 {
  margin-bottom: 0;
}
.logo {
  width: 160px;
  height: 102px;
}
.submit {
  visibility: hidden;
}
.loginTitle {
  margin-top: 30px;
}

.forgotPassword {
  margin-right: 16px!important;
}
.signInContainer {
  margin-bottom: 20px;
  margin-top: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.chromeBroswerRec {
  padding: 10;
  margin-bottom: 15;
  background: lightyellow;
  border: 1px solid #bdbdbd;
  text-align: center;
}
.signUpOuter {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.notaMem {
  margin: 10px 0;
  margin-right: 8;
}
.signupNow {
  color: #ef6c00;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
.freeClient {
  color: #9e9e9e;
  margin: 10px 0;
}

.loginTextpassword {
  margin-top: 10px;
}

.login-form {
  margin-top: 30px;
}
