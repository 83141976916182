.assetManagementComponent {
  display: flex;
  flex: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 667px;
  padding: 10px 40px 40px 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  .registrationQuestions {
    text-align: center;
    font-size: 20px;
    padding: 24px;
  }
  .registrationUpgradeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .registerAssetManagement {
      color: #9e9e9e;
      font-size: 14px;
      font-weight: bold;
    }
    .registrationUpgradePath {
      border-width: 1px;
      border-style: solid;
      border-color: #bdbdbd;
      width: 268px;
      display: flex;
      line-height: 28px;
      align-items: center;
      padding: 18px;
      text-align: center;
      color: #9e9e9e;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}
