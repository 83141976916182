.muiTopFuel {
  background-color: #2196f3 !important;
}

.muiTopGrease {
  background-color: #9c27b0 !important; // material-ui purple[500]
}

.muiBottom {
  background-color: #bdbdbd !important;
}

.muiRed {
  background-color: #f44336 !important; // material-ui red[500]
}

.muiYellow {
  background-color: #ffeb3b !important; // material-ui yellow[500]
}

.bar {
  width: 100%;
  border-radius: 2px;
}
