.ignBlinking {
  animation: blinking infinite 2s;
}

@keyframes blinking {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 0%;
  }
}
