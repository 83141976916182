#Onboarding {
  padding: 10px;
}

#Onboarding,
.onboarding-step,
.action-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-button-group {
  flex-direction: row;
  margin-top: 10px;
}

.stepper-steps {
  width: 50%;
  margin: 20px 0;
}

.step-label {
  font-size: 1.2em;
  font-weight: 700;
}

.logo-container {
  position: relative;
  margin: 20px;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 10px;
}

.logo-container.no-logo-selected {
  border: 1px dashed #cacaca;
}

.logo-container img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}

.onboarding-action-button {
  margin: 5px !important;
}

.remove-logo-button {
  position: absolute !important;
  right: 0;
  top: 0;
}

.branch-name-input,
.timezone-select {
  width: 100%;
}

.timezone-select {
  margin: 10px 0;
}
