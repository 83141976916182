.accountBranchCardLongContainer {
  flex: 1;
  margin-bottom: 7px;
  margin-right: 7px;
  padding-bottom: 25px;
  padding-top: 10px;
  .accountBranchCardHeaderLong {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 10px;
    padding-bottom: 4px;
  }
}

.branchcontainer {
  display: flex;
  justify-content: space-between;
  min-height: 180px;
  min-width: 250px;
  .branchMapOuterBox {
    width: 204px;
    height: 180px;
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    .mapNotAvailable {
      border: 1px solid black;
      width: 180px;
      height: 158px;
    }
  }

  .branchInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.branchInfoBody {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .singleInfoContainer {
    flex: 1;
    min-width: 90px;
    margin-right: 10px;
    .branchTimeZoneContainer {
      display: flex;
      align-items: center;
      margin-top: 10;
    }
  }
}
