.userInfoStyle {
  padding: 8px 16px;
}

.sessionMenu {
  min-width: 160;
  a {
    color: rgba(
      0,
      0,
      0,
      0.87
    ); // needed to avoid the color change that was created globally for this
  }
}
