.mainAppBar {
  height: 64px;
  z-index: 12;
  top: 0;
  position: sticky;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.26);
  .innerMainAppBar {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    box-sizing: border-box;
    .appBarFloatLeft {
      display: flex;
      float: left;
      height: 100%;
      align-items: center;
    }
    .DesktopAppbarTitle {
      z-index: 11;
      width: 100%;
      display: flex;
      justify-content: center;
      align-self: center;
      color: #000;
      position: fixed;
      padding-right: 32px;
    }
    .appBarFloatRight {
      display: flex;
      float: right;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
  }
}

.rovitrackerLogo {
  width: 60px;
  height: 39px;
  margin-top: -10px;
}
