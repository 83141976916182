.loadingOverlayContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
}

.spinner {
  margin-bottom: 50px;
}
