.NotificationsFormContainer {
  display: flex;
  flex-direction: column;
  .NotificationsFormCheckBox {
    padding: 8px 0;
  }
  .NotifSMSNumber {
    margin-left: 40px;
    display: flex;
    align-items: flex-end;
  }
}
