.containerMobile {
  width: 60px;
  height: 4px;
  background: #e0e0e0; // grey[300]
  position: relative;
}

.container {
  width: 120px;
  height: 4px;
  background: #e0e0e0; // grey[300]
  position: relative;
}

.startBar {
  position: absolute;
  left: 0px;
  top: -3px;
  background: #9e9e9e; // grey[500]
  height: 10px;
  width: 2px;
}

.endBar {
  position: absolute;
  left: 100px;
  top: -3px;
  background: #9e9e9e; // grey[500]
  height: 10px;
  width: 2px;
}

.endBarMobile {
  position: absolute;
  left: 50px;
  top: -3px;
  background: #9e9e9e; // grey[500]
  height: 10px;
  width: 2px;
}

.icon {
  top: -7px;
  position: absolute;
}

.discrepancy {
  display: flex;
  align-items: center;
  height: 18px;
}

.scheduled {
  color: #2196f3 !important; // blue[500]
}

.upcoming {
  color: #ff9800 !important; // orange[500]
}

.overdue {
  color: #f44336 !important; // red[500]
}
