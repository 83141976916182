.toolbarContainer {
  width: 100vw;
  z-index: 11;
  .toolbarUpperContainer {
    height: 48px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    .toolbarUpperInner {
      display: flex;
      align-items: center;
      height: 100%;
      color: #fff;
      .noBack {
        padding-left: 24px;
      }
    }
  }
  .toolbarLowerContainer {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}

.compBarContainer {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: inherit;
  z-index: 10;
}