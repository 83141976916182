.desktopToggle {
  position: absolute;
  display: flex;
  top: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  width: 22px;
  height: 48px;
  opacity: 0.6;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

// .childContainer {
//   flex: 1;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   animation: fadeOut ease 1s
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
