.accountPage {
  .accountPageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 11px 4px 4px 11px;
    max-width: 100%;
    .accountPageCards {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
    }
  }
}
