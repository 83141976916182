.registrationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  .registrationTitle {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .registrationTitleWords {
      color: #000;
      font-size: 20px;
    }
  }

  .registrationLogoContainer {
    padding: 20px;
  }

  .registrationInner {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(244, 244, 244);
    width: 90%;
    max-width: 667px;
    padding: 25px 55px 25px 55px;
    .registrationTexts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.textFieldRow {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.textField {
  width: 256px;
  margin-bottom: 14px;
}

.textFieldLeft {
  margin-right: 20px;
}

.registrationCheckBoxes {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding-left: 10px;
}

.checkTerms {
  display: flex;
  align-items: center;
  padding: 5px;
  .checkTermsP {
    margin-right: 5px;
  }
}

.checkP {
  padding: 5px;
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
}

.registerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  .registrationLoginReturnButton {
    padding-right: 10px;
  }
}
