.inventoryAppbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  position: fixed;
  width: calc(100% - 80px);
  z-index: 2;
  .inventoryLeftSide {
    display: flex;
    align-items: center;
    .equipmentTotal {
      padding-left: 16px;
      opacity: 0.54;
      font-size: 15px;
    }
  }
}

.inventoryContainer {
  overflow: scroll;
}

.inventoryRow {
  display: flex;
  align-items: center;
  height: 50px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  width: fit-content;
  .assetImage {
    position: relative;
    width: 64px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    .imageIcons {
      position: absolute;
      top: -3px;
      right: -8px;
      display: flex;
      align-items: center;
      .inService {
        height: 18px;
        width: 18px;
        font-size: 14px;
      }
    }
  }
  .nameBlock {
    display: inline-block;
    min-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    .nametext {
      display: inline-block;
      font-size: 14px;
    }
  }
  .iconsBlock {
    width: 174px;
    margin: 0 7px;
    display: flex;
    align-items: center;
    .iconContainer {
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
