.otherUserPage {
  .otherUserPageBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 96px;
    .otherUserAttributes {
      padding: 10px 32px;
      margin: 25px;
    }
    .otherUserTableOuter {
      margin: 25px;
      .otherUserTable {
        .otherUserTitle {
          margin: 15px;
        }
      }
    }
  }
}
